import React from 'react';
import {
    IWizardStep,
    WizardStepType,
} from '@frontend/jetlend-web-ui/src/components/Wizard/WizardBreadCrumbButton';
import { SectionStretchType } from '@app/components/server/ui/Section/Section';
import { AnimationType } from '@app/components/server/ui/animations';
import yandexIcon from '@app/icons/yandex-icon.svg';
import googlePlayIcon from '@app/icons/google-play-icon.svg';
import appStoreIcon from '@app/icons/app-store-icon.svg';
import { BorrowersSectionType } from '@app/models/borrowers/borrowers';
import { InvestorsSectionType } from '@app/models/investors/investors';
import { PartnersSectionType } from '@app/models/partners/partners';
import { AcademySectionType } from '@app/models/academy/course';


export enum SectionType {
    EXTERNAL_NEWS = 'external_news',
    PARTNERS = 'partners',
    FEEDBACKS = 'feedbacks',
    YOUTUBE = 'youtube',
    STATISTICS = 'statistics',
    MARKUP = 'markup',
}

export interface ISectionBasedProps<ISectionApiModel> {
    section: ISectionApiModel;
}

export interface ISectionConfig {
    component: React.ComponentType;
    hideTitle?: boolean;
    filled?: boolean;
    stretch?: SectionStretchType;
    action?: React.ReactNode | JSX.Element;
    animation?: AnimationType;
}

export interface IBaseSectionApiModel<TType = SectionType> {
    type: TType;
    title: string;
    title_mobile?: string;
    show_registration_form?: boolean;
    hidden: boolean;
}

export type ExtendedBaseSectionApiModel = IBaseSectionApiModel<SectionType | InvestorsSectionType | BorrowersSectionType | PartnersSectionType | AcademySectionType> & {
    [p: string]: any;
}

export type BaseSectionWithBlocks<TBlock> = IBaseSectionApiModel & {
    blocks: TBlock[];
}

export type BaseSectionWithItems<TItem> = IBaseSectionApiModel & {
    items: TItem[];
}

export type BaseSectionWithDocuments<TItem> = IBaseSectionApiModel & {
    documents: TItem[];
}

export enum ClientType {
    Investor = 'investor',
    Borrower = 'borrower',
    Partner = 'partner',
}

export interface IAppBadge {
    src: string;
    href: string;
    title: string;
}

export const CLIENT_TYPE_SCOPE_TITLE: Record<ClientType, string> = {
    [ClientType.Investor]: 'инвестор',
    [ClientType.Borrower]: 'заемщик',
    [ClientType.Partner]: 'партнер',
};

export const CLIENT_TYPE_SCOPE_MAP: Record<ClientType, string> = {
    [ClientType.Investor]: 'client',
    [ClientType.Borrower]: 'business',
    [ClientType.Partner]: 'partner',
};

export const CLIENT_TYPE_LOGIN_URLS: Record<ClientType, string> = {
    [ClientType.Investor]: '/invest/login/',
    [ClientType.Borrower]: '/lend/login/',
    [ClientType.Partner]: '/partner/login/',
};

export const CLIENT_TYPE_RESET_PASS_URLS: Record<ClientType, string> = {
    [ClientType.Investor]: '/invest/reset/',
    [ClientType.Borrower]: '/lend/reset/',
    [ClientType.Partner]: '/partner/reset/',
};

export const CLIENT_TYPE_REGISTRATION_URLS: Record<ClientType, string> = {
    [ClientType.Investor]: '/invest/register/',
    [ClientType.Borrower]: '/lend/register/',
    [ClientType.Partner]: '/partner/register/',
};

export const CLIENT_TYPE_CREATE_URLS: Record<ClientType, string> = {
    [ClientType.Investor]: '/invest/create/',
    [ClientType.Borrower]: '/lend/create/',
    [ClientType.Partner]: '/partner/create/',
};

export const CLIENT_TYPE_CABINET_URLS: Record<ClientType, string> = {
    [ClientType.Investor]: '/invest/v3',
    [ClientType.Borrower]: '/lend/v3',
    [ClientType.Partner]: '/partner/v3',
};

export enum CommonStage {
    Phone = 'phone',
    Sms = 'sms',
    ResetPassword = 'reset_password',
    Email = 'email',
    Loading = 'loading',
    NewPassword = 'new_password',
    Login = 'login',
    Create = 'create',
    Registration = 'registration',
    OauthPhone = 'oauth_phone',
}

export const getClientTitle = (clientType: string) => clientType ? `${clientType}a` : '';

export function getClientTypeFromUrl(url: string): ClientType|undefined {
    if (!url) {
        return undefined;
    }

    if (url.includes('/invest/')) {
        return ClientType.Investor;
    }

    if (url.includes('/lend/')) {
        return ClientType.Borrower;
    }

    if (url.includes('/partner/')) {
        return ClientType.Partner;
    }

    return undefined;
}

export const getCommonSteps = (stage: CommonStage): IWizardStep[] => ([
    {
        id: 0,
        title: 'Ввод телефона',
        type: WizardStepType.Completed,
    },
    {
        id: 1,
        title: 'Подтверждение телефона',
        type: (stage === CommonStage.Sms || stage === CommonStage.Email) && WizardStepType.Completed,
    },
    {
        id: 3,
        title: 'Ввод email',
        type: stage === CommonStage.Email && WizardStepType.Completed,
    },
]);

export const CLIENT_TYPE_CABINET_TARIFF_URLS: Record<ClientType, string> = {
    [ClientType.Investor]: '/invest/v3/profile/tariffs',
    [ClientType.Borrower]: '/lend/v3/statuses',
    [ClientType.Partner]: '',
};

export interface IAuthorApiModel {
    name: string;
    icon: string;
}

type IFeedbacksService = 'Яндекс' | 'Google Play' | 'App Store';

export const SERVICE_ICONS_MAP: Record<IFeedbacksService, string> = {
    'Яндекс': yandexIcon,
    'Google Play': googlePlayIcon,
    'App Store': appStoreIcon,
};

export type FeedbacksSectionApiModel = BaseSectionWithItems<FeedbackItemApiModel>;

export interface FeedbackItemApiModel {
    text: string;
    service: string;
    username: string;
    rating: string;
    date: string;
}

export type PartnersSectionApiModel = BaseSectionWithItems<PartnerItemApiModel>

export interface PartnerItemApiModel {
    title: string;
    image: string;
    url: string;
}

export type YouTubeSectionApiModel = BaseSectionWithItems<IYouTubeItemApiModel>

export interface IYouTubeItemApiModel {
    title: string;
    author?: IYouTubeItemAuthorApiModel;
    date: string;
    url: string;
    preview_url: string;
    small_preview_url: string;
}

export interface IYouTubeItemAuthorApiModel extends IAuthorApiModel {
    verified: boolean;
}

export interface IExternalNewsSectionApiModel extends IBaseSectionApiModel {
    posts: IExternalNewsPostApiModel[];
}

export interface IExternalNewsPostApiModel {
    title: string;
    description: string;
    author: IAuthorApiModel;
    date: string;
    url: string;
}
