declare global {
    interface Window {
        SITE_KEY?: string;
        grecaptcha: {
            ready: (cb: () => void) => void;
            execute: (siteKey: string, options?: object) => Promise<string>;
        };
    }
}

/**
 * TODO
 * @category Services
 */
export function withRecaptcha(action: string): Promise<string> {
    if (typeof window.grecaptcha === 'undefined') {
        console.error('Recaptcha is not installed');
        return Promise.resolve('non-initialized-token');
    }

    return new Promise((resolve, reject) => {
        window.grecaptcha.ready(function() {
            window.grecaptcha
                .execute(window.SITE_KEY || '', {
                    action,
                }).then(function(token) {
                    resolve(token);
                })
                .catch(function(error) {
                    reject(error);
                });
        });
    });
}
