const WORDS_DATABASE = {
    'day': ['день', 'дня', 'дней'],
    'day_parental': ['дня', 'дней', 'дней'],
    'cday': ['календарный день', 'календарных дня', 'календарных дней'],
    'month': ['месяц', 'месяца', 'месяцев'],
    'month_parental': ['месяца', 'месяцев', 'месяцев'],
    'year': ['год', 'года', 'лет'],
    'rub': ['рубль', 'рубля', 'рублей'],
    'rub_parental': ['рубля', 'рублей', 'рублей'],
    'penny': ['копейка', 'копейки', 'копеек'],
    'company': ['компания', 'компании', 'компаний'],
    'business': ['бизнес', 'бизнеса', 'бизнесов'],
    'percent': ['процент', 'процента', 'процентов'],
    'seconds': ['секунда', 'секунды', 'секунд'],
    'minutes': ['минута', 'минуты', 'минут'],
    'blocks': ['блок', 'блока', 'блоков'],
    'hours': ['час', 'часа', 'часов'],
    'hours_parental': ['часа', 'часов', 'часов'],
    'chars': ['символ', 'символа', 'символов'],
    'chars_parental': ['символа', 'символов', 'символов'],
    'contracts': ['контракт', 'контракта', 'контрактов'],
    'contracts_parental': ['контракта', 'контрактов', 'контрактов'],
    'values': ['значение', 'значения', 'значений'],
    'transactions': ['транзакция', 'транзакции', 'транзакций'],
    'lessons': ['урок', 'урока', 'уроков'],
    'tasks': ['задание', 'задания', 'заданий'],
    'gifts': ['награда', 'награды', 'наград'],
    'questions': ['вопрос', 'вопроса', 'вопросов'],
};

/**
 * TODO
 * @category Utils
 */
export function buildWordForm(number: number, words: string[]) {
    const tens = Math.floor(number / 10) % 10;
    const digit = number % 10;

    if (!words) {
        return '';
    }

    if (tens === 1) {
    // 10..19, 110..119 etc. - дней
        return words[2];
    }
    if (digit === 1) {
        // x1 - день
        return words[0];
    } if (2 <= digit && digit <= 4) {
        // x2..x4 - дня
        return words[1];
    }
    // x0, x5..x9 - дней
    return words[2];


}

export type WORD_FORM_SCOPE = keyof typeof WORDS_DATABASE;

/**
 * TODO
 * @category Utils
 */
export function wordForm(number: number, scope: WORD_FORM_SCOPE) {
    return buildWordForm(number, WORDS_DATABASE[scope]);
}

/**
 * TODO
 * @category Utils
 */
export function numWordForm(number: number|string, scope: WORD_FORM_SCOPE, defaultValue = '') {
    if (typeof number === 'undefined') {
        return defaultValue;
    }

    const intNumber = parseInt(number as string);
    if (isNaN(intNumber)) {
        return defaultValue;
    }

    return `${number} ${wordForm(intNumber, scope)}`;
}
