import { withRecaptcha } from '@frontend/jetlend-web-ui/src/services/recaptchaService';
import { apiPostRequest } from '../api';
import { buildClientApiEndpoint } from './common';

declare global {
    interface Window {
        INTEGRITY_TYPE?: string;
    }
}

export async function apiSmsSend(values) {
    const {
        clientType,
        ...payload
    } = values;

    // return { status: 'skip' };
    // return await testPostResponse(666, 'id');

    const token = await withRecaptcha('send_sms');

    return apiPostRequest(buildClientApiEndpoint(clientType, '/api/sms/send_code'), payload, {
        headers: {
            'X-Integrity-Type': (window as any).INTEGRITY_TYPE,
            'X-Integrity-Token': token,
        },
    });
}

export async function apiSmsConfirm(values) {
    const {
        clientType,
        ...payload
    } = values;

    // return await testEmptyResponse();

    return apiPostRequest(buildClientApiEndpoint(clientType, '/api/sms/verify_code'), payload);
}
