import {
    call,
    put,
    select,
    takeEvery,
} from '@redux-saga/core/effects';
import { createSelector } from 'reselect';
import { objectHandler } from './object';
import { createType } from './core';
import {
    ActionCreatorType,
    createUpdateAction,
    UpdateActionType,
} from '@core/store/actions';
import {
    IBaseHandler,
    SimpleSelector,
} from './props';

/**
 * Implement the handler to manage modal state in the app.
 *
 * @see {@link modalHandler}
 * @see {@link modalFormHandler}
 *
 * @category Duck Handlers
 */
export interface IModalHandler extends IBaseHandler {
    /**
     * Redux action. Triggered, when modal will opening.
     * @event
     */
    OPEN: string;
    /**
     * Open the modal.
     * @param key
     */
    open: (key?: any) => UpdateActionType;
    /**
     * Redux action. Triggered, when modal will closing.
     * @event
     */
    CLOSE: string;
    /**
     * Close the modal.
     * @param key
     */
    close: (key?: any) => UpdateActionType;
    /**
     * Selector. Returns the state of current modals.
     * @group Selectors
     */
    isOpen: SimpleSelector<{ [key: number]: boolean } | boolean>;
    update: ActionCreatorType;
}

/**
 * Реализует хендлер {@link IModalHandler} для работы с модальными окнами.
 *
 * @param prefix
 * @param actionName
 * @param dataKey
 * @returns
 *
 * @category Duck Handlers
 *
 * @see {@link IModalHandler}
 * @see {@link useModalHandler}
 * @see {@link useModalHandlerWithKey}
 * @see {@link useIsModalOpen}
 * @see {@link useIsModalOpenWithKey}
 */
export function modalHandler(prefix, actionName, dataKey): IModalHandler {
    const handler = objectHandler(prefix, dataKey);
    const {
        update,
        selector,
        reducer,
        reducerInfo,
    } = handler;

    const OPEN = createType(prefix, `OPEN_${actionName}`);
    const CLOSE = createType(prefix, `CLOSE_${actionName}`);

    const open = createUpdateAction(OPEN);
    const close = createUpdateAction(CLOSE);

    function* changeOpenState(key, newState) {
        if (key !== undefined) {
            const state = yield select(selector);
            const currentOpenState = state.isOpen || {};
            yield put(update({
                isOpen: {
                    ...currentOpenState,
                    [key]: newState,
                },
            }));
        } else {
            yield put(update({ isOpen: newState }));
        }
    }

    function* openSaga({ value }) {
        yield call(changeOpenState, value, true);
    }

    function* closeSaga({ value }) {
        yield call(changeOpenState, value, false);
    }

    const effects = [
        takeEvery(OPEN as any, openSaga),
        takeEvery(CLOSE as any, closeSaga),
    ];

    return {
        OPEN,
        CLOSE,
        open,
        close,
        update,
        selector,
        isOpen: createSelector(selector, data => data?.isOpen),
        reducer,
        reducerInfo,
        effects,
    };
}