import { ApiDataResponse } from '@frontend/jetlend-core/src/models/api';
import {
    apiGetRequest,
    apiPostRequest,
} from '../api';
import { buildClientApiEndpoint } from './common';
import { ILoginResultApiModel } from '@app/models/common/login';
import { ClientType } from '@app/models/common/common';
import {
    IExternalAuthVariantApiModel,
    IExternalLoginValues,
    IOauthPhoneRegistrationValues,
} from '@app/models/common/oauth';

/**
 * Метод для получения списка доступных типов внешних авторизаций определенного клиента.
 */
export async function apiGetExternalLoginVariants(clientType: ClientType): Promise<ApiDataResponse<IExternalAuthVariantApiModel[]>> {
    try {
        return await apiGetRequest(buildClientApiEndpoint(clientType, '/api/oauth'));
    } catch (e) {
        console.warn(`GET ${buildClientApiEndpoint(clientType, '/api/oauth')} failed`, e);
    }
}

/**
 * Метод для инициирования процесса авторизации через внешнюю систему.
 */
export async function apiPostExternalUserLogin(values: IExternalLoginValues): Promise<ApiDataResponse<ILoginResultApiModel>> {
    const {
        clientType,
        auth_variant,
        ...payload
    } = values;

    return apiPostRequest(buildClientApiEndpoint(clientType, `/api/oauth/${auth_variant}`), payload);
}

/**
 * Метод для регистрации номера телефона, если в аккаунте внешнего сервиса, номер телефона не был указан.
 */
export async function apiPostExternalPhoneRegistration(values: IOauthPhoneRegistrationValues): Promise<ApiDataResponse<ILoginResultApiModel>> {
    const {
        clientType,
        ...payload
    } = values;

    return apiPostRequest(buildClientApiEndpoint(clientType, '/api/oauth/phone'), payload);
}