import {
    ApiDataResponse,
    BaseApiResponse,
} from '@frontend/jetlend-core/src/models/api';
import {
    ClientType,
    IExternalAuthVariantApiModel,
} from '@app/models/common/common';
import {
    IEmailFormValues,
    IPhoneRegistrationFormValues,
    IRegistrationFormValues,
    IRegistrationResultApiModel,
    IExternalRegistrationValues,
} from '@app/models/common/registration';
import {
    apiGetRequest,
    apiPostRequest,
} from '../api';
import { buildClientApiEndpoint } from './common';
import { testResponse } from '../api.mock';

export async function apiPostPhoneRegistration(values: IPhoneRegistrationFormValues): Promise<BaseApiResponse> {
    const {
        clientType,
        ...payload
    } = values;

    // return await testErrorResponse('Пользователь уже зарегистрирован');
    // return await testEmptyResponse();

    return apiPostRequest(buildClientApiEndpoint(clientType, '/api/register/phone'), payload);
}

export async function apiPostUserRegistration(values: IRegistrationFormValues): Promise<ApiDataResponse<IRegistrationResultApiModel>> {
    const {
        clientType,
        ...payload
    } = values;

    // return await testErrorResponse('Ошибка создания аккаунта');
    // return await testEmptyResponse(3000);

    // if(payload.redirect_uri) {
    //     return await testResponse({
    //         redirect_uri: payload.redirect_uri,
    //     });
    // } else {
    //     return await testEmptyResponse();
    // }

    return apiPostRequest(buildClientApiEndpoint(clientType, '/api/register'), payload);
}

export async function apiPostProfileEmailUpdate(values: IEmailFormValues): Promise<BaseApiResponse> {
    const {
        clientType,
        ...payload
    } = values;

    return apiPostRequest(buildClientApiEndpoint(clientType, '/api/profile/email/edit'), payload);
}

export async function apiGetExternalRegistrationVariants(clientType: ClientType): Promise<IExternalAuthVariantApiModel[]> {
    try {
        return await apiGetRequest(buildClientApiEndpoint(clientType, '/api/public/landing/oauth'));
    } catch {
        return await testResponse([]);
    }
}

export async function apiPostExternalUserRegistration(values: IExternalRegistrationValues): Promise<IRegistrationResultApiModel> {
    const {
        clientType,
        auth_variant,
        ...payload
    } = values;

    return apiPostRequest(buildClientApiEndpoint(clientType, `/api/public/lading/oauth/${auth_variant}`), payload);
}