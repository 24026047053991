import {
    ApiDataResponse,
    BaseApiResponse,
} from '@frontend/jetlend-core/src/models/api';
import { ClientType } from '@app/models/common/common';
import {
    ILoginFormValues,
    ILoginResultApiModel,
} from '@app/models/common/login';
import { buildClientApiEndpoint } from '@app/services/client/common/common';
import { apiPostRequest } from '../api';

export async function apiPostLogin(values: ILoginFormValues): Promise<ApiDataResponse<ILoginResultApiModel>> {
    const {
        clientType,
        ...payload
    } = values;

    // if (payload.password !== '1') {
    //     await testBadResponse(400, {
    //         password: 'Неверный пароль',
    //     }, 3000);
    // }

    // if(payload.redirect_uri) {
    //     return await testResponse({
    //         return_uri: payload.redirect_uri,
    //     });
    // } else {
    //     return await testEmptyResponse();
    // }

    return apiPostRequest(buildClientApiEndpoint(clientType, '/api/auth/login'), payload);
}

export async function apiPostPasswordReset(values: ILoginFormValues): Promise<BaseApiResponse> {
    const {
        clientType,
        ...payload
    } = values;

    // return await testErrorResponse('Неверный пароль');
    // return await testEmptyResponse();

    return apiPostRequest(buildClientApiEndpoint(clientType, '/api/auth/password/reset'), payload);
}

export async function apiPostUserCreate(values: ILoginFormValues): Promise<BaseApiResponse> {
    const {
        clientType,
        ...payload
    } = values;

    // return await testErrorResponse('Ошибка создания профиля');

    return apiPostRequest(buildClientApiEndpoint(clientType, '/api/account/users/create'), payload);
}

export async function apiPostUserLogout(values: {clientType: ClientType}): Promise<BaseApiResponse> {
    const { clientType } = values;
    return apiPostRequest(buildClientApiEndpoint(clientType, '/api/auth/logout'), {});
}
