import { takeEvery } from '@redux-saga/core/effects';
import { Action } from 'redux';
import {
    UpdateActionType,
    createUpdateAction,
} from '@core/store/actions';
import { createType } from './core';
import { IBaseHandler } from './props';
import { UnexpectedSagaError } from './utils';

/**
 * Implements a simple callback to handle any logic on foreground and independent from UI components.
 *
 * @category Duck Handlers
 */
export interface ISagaHandler<TPayload> extends IBaseHandler {
    /**
     * Redux action to trigger saga.
     * @event
     */
    ACTION: string;

    /**
     * Action creator to trigger saga.
     * @param params
     */
    action: (value?: Partial<TPayload>) => Action;

    /**
     * @deprecated
     * Предназначено только для тестирования.
     */
    __saga: (value?: Partial<TPayload>) => any;
}

interface SagaHandlerOptions<TKey extends string = 'value'> {
    key?: TKey;
}

/**
 * Implements a simple callback to handle any logic on foreground and independent from UI components.
 *
 * @deprecated Please use {@link simpleSagaHandlerV2} instead.
 *
 * @param prefix
 * @param actionName
 * @param saga
 * @param options
 * @returns
 *
 * @category Duck Handlers
 */
export function simpleSagaHandler<TPayload = unknown, TKey extends string = 'value'>(
    prefix: string,
    actionName: string,
    saga: (action: UpdateActionType<TPayload, TKey>) => any,
    options: SagaHandlerOptions<any> = {}
): ISagaHandler<TPayload> {
    const actionKey = options.key || 'value';

    const ACTION = createType(prefix, actionName);
    const action = createUpdateAction(ACTION, actionKey);

    function* handleActionEvent(event) {
        try {
            yield* saga(event);
        } catch (cause) {
            const error = new UnexpectedSagaError(
                cause.message,
                cause,
                actionName,
                prefix
            );

            console.error(error);
        }
    }

    const effects = [
        takeEvery(ACTION, handleActionEvent as any),
    ];

    return {
        ACTION,
        action,
        effects,
        __saga: saga as any,
    };
}
