'use client';

import React, {
    useCallback,
    useRef,
} from 'react';

import Tippy, { TippyProps } from '@tippyjs/react';
import { Placement as TippyPosition } from 'tippy.js';
import {
    buildClassNames,
    mergeClassNames,
} from '../../utils/classNameUtils';

import styles from './tooltipIcon.module.scss';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/shift-away.css';
import { useContainerWrapper } from '@ui/hooks/useContainerWrapper';

const TOOLTIP_DELAY_MS = 175;

export interface TooltipProps extends React.PropsWithChildren {
    title: string | JSX.Element | React.ReactNode;
    position?: TippyPosition;
    className?: string;
    containerClassName?: string;
    open?: boolean;
    distance?: number;
    offset?: number;
    arrow?: boolean;
    disabled?: boolean;
    disableWrapper?: boolean;
    interactive?: boolean;
    onOpen?: () => void;
    onClose?: () => void;
}

const Tooltip: React.FC<TooltipProps> = props => {
    const {
        title,
        children,
    } = props;

    const didOpened = useCallback(
        () => props.onOpen && props.onOpen(),
        [props.onOpen]
    );

    const didClosed = useCallback(
        () => props.onClose && props.onClose(),
        [props.onClose]
    );

    const className = mergeClassNames([
        buildClassNames(styles, ['tooltip-container']),
        props.className,
    ]);

    const commonProps: TippyProps = {
        delay: TOOLTIP_DELAY_MS,
        offset: [props.offset || 0, props.distance || 10],
        placement: props.position || 'bottom',
        className,
        arrow: props.arrow,
        disabled: props.disabled,
        onShow: didOpened,
        onHide: didClosed,
        animation: 'shift-away',
        maxWidth: 400,
        zIndex: 9999,
        interactive: props.interactive,
    };

    const containerRef = useRef<HTMLDivElement>(null);

    useContainerWrapper(containerRef, [children]);

    if (props.disableWrapper) {
        if (title) {
            return (
                <Tippy content={title} {...commonProps}>
                    {children as any}
                </Tippy>
            );
        }

        return children;
    }

    if (title) {
        return (
            <Tippy content={title} {...commonProps}>
                <div ref={containerRef} className={props.containerClassName}>
                    {children}
                </div>
            </Tippy>
        );
    }

    return <div className={props.containerClassName}>{children}</div>;
};

export default Tooltip;